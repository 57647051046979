import { useDispatch } from 'react-redux';
import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import ThemeButton from 'components/buttons/ThemeButton';
import KariTraaIcon from 'components/icons/KariTraaIcon';
import LogOutIcon from 'components/icons/LogOutIcon';
import OverlayHeader from 'components/overlays/OverlayHeader';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    width: '100vw',
    padding: '8px',
    height: `calc(100vh - var(--height-header))`,
});

const Icon = styled('img', {
    marginRight: '8px',
});

const InnerWrapper = styled('div', {
    backgroundColor: 'var(--color-bg-secondary)',
    borderRadius: '8px',
    padding: '12px',
    height: '100%',
});

const Navigation = styled('div', {
    height: `calc(100% - var(--height-header))`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const Heading = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const InternalMenu = styled('div', {
    width: '100%',
    marginBottom: '10px',
});

const NavigationOverlay = () => {
    const [css] = useStyletron();
    const { internalMenu, externalMenu } = useAccountQuery();
    const activeClass = css({
        color: 'var(--color-link)',
    });
    const dispatch = useDispatch();

    return (
        <Wrapper>
            <InnerWrapper>
                <Heading>
                    <Paragraph $style={{ color: 'var(--color-text-account)' }} as="p" fontKeys="Misc/14_100_-1_500_UC">
                        {fm('Account')}
                    </Paragraph>
                    <KariTraaIcon width="80px" />
                    <OverlayHeader overlayId="account-navigation" />
                </Heading>
                <Navigation>
                    <ul className={css({ gap: '16px' })}>
                        {internalMenu &&
                            internalMenu.map((internalMenuItem, index) => (
                                <InternalMenu key={index}>
                                    <ThemeButton
                                        as={Link}
                                        activeClassName={activeClass}
                                        className={css({
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            padding: '0',
                                        })}
                                        fontKeys="Primary/24_94_-1_700"
                                        theme="link"
                                        rel="nofollow"
                                        to={internalMenuItem.link.url}
                                    >
                                        <Paragraph
                                            $style={{ whiteSpace: 'noWrap' }}
                                            as="p"
                                            fontKeys="Primary/18_120_-1"
                                        >
                                            {internalMenuItem.label}
                                        </Paragraph>
                                    </ThemeButton>
                                </InternalMenu>
                            ))}
                    </ul>
                    <ul>
                        {externalMenu &&
                            externalMenu.map((externalMenuItem, index) => {
                                const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                                return (
                                    <li
                                        key={index}
                                        className={css({
                                            marginBottom: '10px',
                                        })}
                                    >
                                        <ThemeButton
                                            as={Link}
                                            activeClassName={activeClass}
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                padding: '0',
                                            })}
                                            fontKeys="Misc/14_100_500"
                                            theme="link"
                                            rel="nofollow"
                                            to={externalMenuItem.link.url}
                                        >
                                            {icon && <Icon src={icon} />}
                                            {externalMenuItem.label}
                                        </ThemeButton>
                                    </li>
                                );
                            })}
                        <li
                            className={css({
                                width: '100%',
                                borderTop: '1px solid var(--color-border-account)',
                                paddingTop: '18px',
                            })}
                        >
                            <ThemeButton
                                as={Link}
                                activeClassName={activeClass}
                                className={css({
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                    padding: '0',
                                })}
                                theme="link"
                                rel="nofollow"
                                to="/"
                                onClick={() => dispatch(signOut())}
                            >
                                <LogOutIcon $style={{ marginRight: '8px' }} />
                                <Paragraph as="p" fontKeys="Misc/14_100_500">
                                    {fm('Log out')}
                                </Paragraph>
                            </ThemeButton>
                        </li>
                    </ul>
                </Navigation>
            </InnerWrapper>
        </Wrapper>
    );
};

export default NavigationOverlay;
